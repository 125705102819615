"use client";

import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";

const dividerVariants = cva(
    "before:block-size after:block-size flex flex-1 shrink-0 items-center whitespace-nowrap text-center text-content-secondary before:me-4 before:basis-1/2 after:ms-4 after:basis-1/2",
    {
        variants: {
            color: {
                default: "before:bg-background-tertiary after:bg-background-tertiary",
                white: "before:bg-background-primary after:bg-background-primary"
            }
        },
        defaultVariants: {
            color: "default"
        }
    }
);

const Divider = React.forwardRef<HTMLElement, VariantProps<typeof dividerVariants> & React.HTMLAttributes<HTMLDivElement>>(({ children, color, ...props }, ref) => {
    const classConditions = {
        "before:me-0 after:ms-0": !children
    };
    return (
        <div role="separator" ref={ref as React.LegacyRef<HTMLDivElement> | undefined} className={cn(dividerVariants({ color }), classConditions)} {...props}>
            {children}
        </div>
    );
});

Divider.displayName = "Divider";

export { Divider, dividerVariants };
