"use client";

import * as React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Circle } from "lucide-react";

import { cn } from "@/shared/utils";

const RadioGroup = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Root>, React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>>(
    ({ className, ...props }, ref) => {
        return <RadioGroupPrimitive.Root className={cn("grid gap-2", className)} {...props} ref={ref} />;
    }
);
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Item>, React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>>(
    ({ className, ...props }, ref) => {
        return (
            <RadioGroupPrimitive.Item
                ref={ref}
                className={cn(
                    "relative aspect-square size-5 shrink-0 rounded-full border border-stroke-tertiary focus:!rounded-full focus:outline-none disabled:cursor-not-allowed disabled:opacity-50",
                    props.checked ? "bg-background-success-secondary" : "bg-background-primary",
                    className
                )}
                {...props}
            >
                <RadioGroupPrimitive.Indicator className="z-10 flex items-center justify-center">
                    <Circle className="size-[9px] rounded-full bg-background-primary text-content-primary-invert" />
                </RadioGroupPrimitive.Indicator>
            </RadioGroupPrimitive.Item>
        );
    }
);
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
