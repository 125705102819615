"use client";

import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { Check, ChevronDown } from "lucide-react";
import { cn } from "@/shared/utils";

const SelectRoot = SelectPrimitive.Root;
const SelectGroup = SelectPrimitive.Group;
const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>>(
    ({ className, children, ...props }, ref) => (
        <SelectPrimitive.Trigger
            ref={ref}
            className={cn(
                "flex h-11 max-h-11 min-h-0 w-full items-center justify-between rounded-md bg-background-primary px-4 py-3 text-left text-base text-content-secondary shadow-faux-border disabled:cursor-not-allowed disabled:opacity-50",
                className
            )}
            {...props}
        >
            {children}
            <SelectPrimitive.Icon asChild>
                <ChevronDown className="size-4 opacity-50" />
            </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>
    )
);
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & { constrainWidth?: boolean }
>(({ className, children, position = "popper", constrainWidth = false, ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={cn(
                "relative z-50 max-h-[300px] min-w-[8rem] overflow-hidden rounded-md border border-stroke-primary bg-background-primary shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                position === "popper" && "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
                className
            )}
            position={position}
            {...props}
        >
            <SelectPrimitive.Viewport
                className={cn("p-1", constrainWidth && "w-[var(--radix-select-trigger-width)]")}
                style={{
                    maxHeight: "var(--radix-select-content-available-height)"
                }}
            >
                {children}
            </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
));

SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Label>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>>(
    ({ className, ...props }, ref) => <SelectPrimitive.Label ref={ref} className={cn("py-1.5 pl-8 pr-2 text-sm font-medium", className)} {...props} />
);
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>>(
    ({ className, children, ...props }, ref) => (
        <SelectPrimitive.Item
            ref={ref}
            className={cn(
                "relative flex w-full cursor-default select-none items-center rounded-sm py-2 pl-8 pr-2 text-sm text-content-primary outline-none hover:bg-background-secondary focus:bg-background-secondary data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
                className
            )}
            {...props}
        >
            <span className="absolute left-2 flex size-[14px] items-center justify-center">
                <SelectPrimitive.ItemIndicator>
                    <Check className="size-4" />
                </SelectPrimitive.ItemIndicator>
            </span>
            <SelectPrimitive.ItemText asChild>
                <div className="block w-full truncate">{children}</div>
            </SelectPrimitive.ItemText>
        </SelectPrimitive.Item>
    )
);

SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Separator>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>>(
    ({ className, ...props }, ref) => <SelectPrimitive.Separator ref={ref} className={cn("h-px -mx-1 my-1", className)} {...props} />
);
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

interface SelectOption {
    value: string;
    label: string;
}
interface SelectFieldProps {
    value: string | undefined;
    options: SelectOption[];
    placeholder?: string;
    onValueChange: (value: string) => void;
    className?: string;
    error?: string;
    disabled?: boolean;
    triggerProps?: React.HTMLAttributes<HTMLButtonElement>;
    constrainWidthToTrigger?: boolean;
}

const Select = React.forwardRef<HTMLDivElement, SelectFieldProps>(
    ({ value, options, placeholder, error, className, onValueChange, disabled, triggerProps, constrainWidthToTrigger = false }, ref) => {
        const selectClassConditions = {
            "text-content-primary": !!value,
            "shadow-faux-border-danger": !!error
        };
        return (
            <>
                <div tabIndex={-1} ref={ref} className="pointer-events-none absolute left-0 top-0 size-[1px] opacity-0"></div>
                <SelectRoot value={value} onValueChange={onValueChange} disabled={disabled}>
                    <SelectTrigger className={cn("relative h-11 min-w-[142px]", selectClassConditions, className)} {...triggerProps}>
                        <span className="block w-full truncate">
                            <SelectValue placeholder={placeholder} />
                        </span>
                    </SelectTrigger>
                    <SelectContent constrainWidth={constrainWidthToTrigger}>
                        <SelectGroup
                            ref={ref => {
                                if (!ref) return;
                                ref.addEventListener("touchend", e => {
                                    if (e.cancelable) e.preventDefault();
                                });
                            }}
                        >
                            {options.map(option => (
                                <SelectItem key={option.value} value={String(option.value)}>
                                    {option.label}
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </SelectRoot>
            </>
        );
    }
);

Select.displayName = "Select";

export { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectLabel, SelectItem, SelectSeparator, type SelectOption };
