import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/shared/utils";

const iconButtonVariants = cva("inline-flex items-center justify-center rounded-md disabled:pointer-events-none disabled:opacity-50", {
    variants: {
        variant: {
            default: "",
            destructive: "text-content-danger"
        },
        size: {
            default: "p-1",
            petInfo: "absolute -mt-6 p-1"
        }
    },
    defaultVariants: {
        variant: "default",
        size: "default"
    }
});

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof iconButtonVariants> {
    asChild?: boolean;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(({ className, type = "button", variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(iconButtonVariants({ variant, size, className }))} ref={ref} type={type} {...props} />;
});

IconButton.displayName = "IconButton";

export { IconButton, iconButtonVariants };
