import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/shared/utils";

const badgeVariants = cva("inline-flex items-center rounded-full px-2 text-xs leading-5 transition-colors focus:outline-none", {
    variants: {
        variant: {
            default: "bg-background-brand-secondary text-content-primary-invert",
            secondary: "bg-background-success text-content-success",
            destructive: "bg-background-danger text-content-primary-invert",
            outline: "border border-stroke-brand bg-background-primary text-content-brand",
            ghost: "text-content-primary"
        }
    },
    defaultVariants: {
        variant: "default"
    }
});

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
    return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
