"use client";

import { create } from "zustand";
import { FieldValues } from "react-hook-form";

type DirtyValuesStore<T extends FieldValues> = {
    dirtyValues: Partial<T>;
    updateDirtyValues: (values: Partial<T>) => void;
    resetDirtyValues: () => void;
};

const useDirtyValuesStore = create<DirtyValuesStore<any>>(set => ({
    dirtyValues: {},
    updateDirtyValues: values => set(state => ({ dirtyValues: { ...state.dirtyValues, ...values } })),
    resetDirtyValues: () => set({ dirtyValues: {} })
}));

export function useDirtyValues<T extends FieldValues>(): DirtyValuesStore<T> {
    const store = useDirtyValuesStore();
    return {
        dirtyValues: store.dirtyValues as Partial<T>,
        updateDirtyValues: store.updateDirtyValues as (values: Partial<T>) => void,
        resetDirtyValues: store.resetDirtyValues
    };
}
