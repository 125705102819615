import { FieldValues, UseFormSetValue } from "react-hook-form";
import { Quote } from "../types/Quote.interface";
import { Species } from "spot-types/entities/Species";
import { QuoteAPI } from "./QuoteAPI";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { AnalyticsUtils } from "./AnalyticsUtils";
import { PublicConfig } from "../PublicConfig";

export type BuilderDataType = keyof (typeof BuilderUtils)["typeToFieldKeyMap"];

export type BuilderTargetingAttributes = {
    cobrandId?: string;
    formId?: string;
    formStepId?: string;
    partnerCodeLowerCase?: string;
    quoteHasCats?: boolean;
    quoteHasDogs?: boolean;
    quoteHasIllnessCoverage?: boolean;
    quoteLocation?: string;
    quotePetCount?: string;
    quoteUnderwriter?: PetUnderwriterType;
    queryParamsStringLowerCase?: string;
    statsigLayerId?: string;
    statsigRuleId?: string;
    statsigAllocatedExperimentName?: string;
};

export class BuilderUtils {
    quoteApi: QuoteAPI;

    constructor(underwriter: PetUnderwriterType) {
        this.quoteApi = new QuoteAPI(underwriter);
    }

    static getBuilderAttributes = ({
        data,
        formId,
        formStepId,
        priorityCode
    }: {
        data?: Quote;
        formId?: string;
        formStepId?: string;
        priorityCode?: string;
    }): BuilderTargetingAttributes => {
        const attributes: BuilderTargetingAttributes = {
            formId: formId,
            formStepId: formStepId,
            partnerCodeLowerCase: priorityCode ?? undefined,
            quoteHasCats: data?.policies?.some(policy => policy.species === Species.Cat) ?? false,
            quoteHasDogs: data?.policies?.some(policy => policy.species === Species.Dog) ?? false,
            quoteHasIllnessCoverage: data?.policies?.some(policy => policy.coverageSettings?.coverages?.some(coverage => coverage.type === "illness")),
            quoteLocation: data?.ratingAddress?.state,
            quotePetCount: (data?.policies?.length ?? 0).toString(),
            quoteUnderwriter: data?.underwriter,
            queryParamsStringLowerCase: ""
        };

        const statsigData = AnalyticsUtils.getStatsigExposureData(PublicConfig.STATSIG_LAYER_ID_MAIN);

        if (statsigData) {
            attributes.statsigAllocatedExperimentName = statsigData.statsig?.allocatedExperimentName;
            attributes.statsigLayerId = statsigData.statsig?.layer;
            attributes.statsigRuleId = statsigData.statsig?.ruleID?.split(":")[0];
        }

        return attributes;
    };

    private static typeToFieldKeyMap = {
        modal: "modalStates",
        form: "formData"
        // Additional types can be added here
    };

    async updateQuoteExtraData({
        quote,
        newDataArray,
        updateQuote
    }: {
        quote?: Quote;
        newDataArray: { type: BuilderDataType; data: FieldValues }[];
        updateQuote?: UseFormSetValue<Quote>;
    }) {
        if (!quote) return;
        const quoteId = quote?.id;
        const currentExtraData = quote?.extra ?? {};
        const updatedExtraData = { ...currentExtraData };

        for (const newData of newDataArray) {
            const fieldKey = BuilderUtils.typeToFieldKeyMap[newData.type];
            const currentFieldData = updatedExtraData[fieldKey] ?? {};
            updatedExtraData[fieldKey] = { ...currentFieldData, ...newData.data };
        }

        const quoteStatus = quote?.quoteStatus;

        if (quoteStatus === "finalized" && !!quoteId) {
            await this.quoteApi.setScratchPadValue(quoteId, "extra", updatedExtraData);
        } else if (updateQuote) {
            updateQuote("extra", updatedExtraData);
        }
    }
}
