import * as React from "react";
import { Input, InputProps } from "./Input";
import { Label } from "./label";
import { RefCallback, RefObject } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";
import { ErrorMessage } from "../FormField";

const inputLabelVariants = cva("relative flex", {
    variants: {
        variant: {
            default: "",
            floating: "items-center rounded-md border border-none bg-background-primary shadow-faux-border"
        }
    },
    defaultVariants: {
        variant: "default"
    }
});

interface InputWithLabelProps extends VariantProps<typeof inputLabelVariants> {
    id?: string;
    label: string;
    inputProps?: InputProps;
    inputRef?: RefObject<HTMLInputElement> | RefCallback<HTMLInputElement>;
    startDecorator?: React.ReactNode;
    wrapperClass?: string;
    error?: ErrorMessage;
}

const InputWithLabel = (props: InputWithLabelProps) => {
    const { id, label, wrapperClass, inputProps, inputRef, startDecorator, variant, error } = props;
    const inputId = id ?? label?.split(" ").join("-").toLowerCase();
    const inputHasValue = String(inputProps?.value ?? "").length > 0;
    const inputClass = `input-value-${inputHasValue}`;
    const isDefaultVariant = variant === "default" || variant === undefined;
    const placeholder = inputProps?.placeholder ?? " ";

    const labelClassConditions = {
        "left-10": startDecorator && !inputHasValue,
        "absolute -top-6": isDefaultVariant
    };

    const decoratorClassConditions = {
        "absolute bottom-3 left-3 pl-0 z-50": isDefaultVariant
    };

    return (
        <div className={cn(inputLabelVariants({ variant }), wrapperClass)}>
            {startDecorator && <span className={cn("flex pl-3", decoratorClassConditions)}>{startDecorator}</span>}
            <Input
                id={inputId}
                variant={variant}
                className={cn(inputClass, { "pl-12": isDefaultVariant && !!startDecorator })}
                ref={inputRef}
                placeholder={placeholder}
                error={error}
                {...inputProps}
            />
            <Label htmlFor={inputId} variant={variant} bgColor={isDefaultVariant ? "transparent" : "default"} className={cn(labelClassConditions)}>
                {label}
            </Label>
        </div>
    );
};

InputWithLabel.displayName = "InputWithLabel";

export { InputWithLabel };
