"use client";

import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";

import { cn } from "@/shared/utils";

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
    error?: string;
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(({ className, error, ...props }, ref) => {
    const conditionalClasses = {
        "shadow-faux-border-danger border-none": !!error
    };

    return (
        <CheckboxPrimitive.Root
            ref={ref}
            className={cn(
                "peer size-5 shrink-0 rounded border-2 border-stroke-tertiary bg-background-primary focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-none data-[state=checked]:bg-background-success-secondary data-[state=checked]:font-extrabold data-[state=checked]:text-content-primary-invert",
                className,
                conditionalClasses
            )}
            {...props}
        >
            <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-content-current")}>
                <Check className="size-4" />
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
    );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
