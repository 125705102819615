import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";
import { ErrorMessage } from "../FormField";

const inputVariants = cva(
    "h-11 w-full appearance-none rounded-md bg-background-primary px-4 text-base text-content-primary shadow-faux-border placeholder:text-content-secondary focus:outline-none",
    {
        variants: {
            variant: {
                default: "",
                floating: "peer placeholder:opacity-0 focus:placeholder:opacity-100"
            }
        },
        defaultVariants: {
            variant: "default"
        }
    }
);

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
    error?: ErrorMessage;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, variant, type, placeholder = "", error, ...props }, ref) => {
    return (
        <input
            type={type}
            className={cn(
                inputVariants({ variant }),
                {
                    "shadow-faux-border-danger": !!error
                },
                className
            )}
            placeholder={placeholder}
            ref={ref}
            {...props}
        />
    );
});

Input.displayName = "Input";

export { Input };
