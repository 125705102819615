"use client";

import * as React from "react";
import { cn } from "@/shared/utils";

const headings = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5"
} as const;

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
    level: keyof typeof headings;
    children: React.ReactNode;
    className?: string;
    styleAs?: keyof typeof headings;
}

const Heading = (props: HeadingProps) => {
    const { level, children, styleAs, className } = props;
    const HeadingLevel = headings[level];
    const finalClassName = cn(styleAs, className, level === "h3" && "font-bold");

    return <HeadingLevel className={finalClassName}>{children}</HeadingLevel>;
};

Heading.displayName = "Heading";

export { Heading };
