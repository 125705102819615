import { z } from "zod";
import { PetQuote, PetUnderwriterType } from "spot-types/entities/PetQuote";
import { Species } from "spot-types/entities/Species";
import axios, { AxiosRequestConfig, Method } from "axios";
import { PublicConfig } from "../PublicConfig";
import { PetBreed } from "spot-types/entities/PetBreed";
import { Coverage } from "spot-types/entities/Coverage";
import { Address } from "spot-types/entities/Address";
import { PTZBillingInformation } from "../types/TempBilling";
import { Quote } from "../types/Quote.interface";
import { PTZQuote } from "spot-types/vendor/ptz/PTZQuoteTypes";

const ThankYouUrlSchema = z
    .object({
        extra: z.object({
            thankYouURL: z.string()
        })
    })
    .nonstrict();

export class QuoteAPI {
    underwriter: PetUnderwriterType;

    constructor(underwriter: PetUnderwriterType) {
        this.underwriter = underwriter;
    }

    static async call<T = any>(method: Method, endpoint: string, underwriter: PetUnderwriterType, options: AxiosRequestConfig = {}): Promise<T> {
        const params = { ...options.params, uw: underwriter };
        const url = PublicConfig.SPOT_API_ROOT_URL + endpoint;
        try {
            const request = await axios(url, {
                ...options,
                params,
                method
            });
            return request.data;
        } catch (error) {
            throw error;
        }
    }

    static async callPtz<T = any>(method: Method, endpoint: string, options: AxiosRequestConfig = {}): Promise<T> {
        const url = PublicConfig.PTZ_US.UNDERWRITER_API_URL + endpoint;
        try {
            const request = await axios(url, {
                ...options,
                method
            });
            return request.data;
        } catch (error) {
            throw error;
        }
    }

    async getQuote(quoteID: string): Promise<PetQuote | undefined> {
        return QuoteAPI.call("GET", `/quoting/quotes/${quoteID}`, this.underwriter);
    }

    async upsertQuote(quote: PetQuote, queryParams?: { [key: string]: any }): Promise<PetQuote> {
        return QuoteAPI.call("POST", `/quoting/quotes`, this.underwriter, {
            data: quote,
            params: queryParams
        });
    }

    async updateQuote(quote: PetQuote, queryParams?: { [key: string]: any }): Promise<PetQuote> {
        if (!quote.quoteId) {
            return this.upsertQuote(quote, queryParams);
        }
        return QuoteAPI.call("PUT", `/quoting/quotes/${quote.quoteId}`, this.underwriter, {
            data: quote,
            params: queryParams
        });
    }

    async getBreeds(species?: Species): Promise<PetBreed[]> {
        let url = `/quoting/values/pets/breeds`;
        if (species) {
            url += `/${species}`;
        }
        return QuoteAPI.call("GET", url, this.underwriter);
    }

    async getPlans(quoteID: string, petID: string): Promise<{ baseCoverage: Coverage; riders: Coverage[] }[] | undefined> {
        return QuoteAPI.call("GET", `/quoting/quotes/${quoteID}/pets/${petID}/plans`, this.underwriter);
    }

    async setThankYouUrl(quote: PetQuote, thankYouUrl: string): Promise<PetQuote | undefined> {
        if (!quote?.quoteId) {
            return undefined;
        }
        const response = await QuoteAPI.call("PUT", `/quoting/quotes/${quote.quoteId}`, this.underwriter, {
            data: { ...quote, extra: { ...quote.extra, thankYouURL: thankYouUrl } }
        });

        try {
            ThankYouUrlSchema.parse(response);
            return response;
        } catch (e) {
            console.error("Failed to parse thankYouURL:", e);
            return undefined;
        }
    }

    async getExtraValue(quoteID: string, key: string): Promise<Record<string, any> | undefined> {
        return QuoteAPI.call("GET", `/quoting/quotes/${quoteID}/scratchpad/${key}`, this.underwriter);
    }

    async finalizeQuote(quoteID: string, simulateTimeout?: boolean): Promise<PetQuote | undefined> {
        const shouldTimeout = !!simulateTimeout && PublicConfig.ENVIRONMENT !== "production";
        return QuoteAPI.call("POST", `/quoting/quotes/${quoteID}/finalize${shouldTimeout ? "?sim=timeout" : ""}`, this.underwriter);
    }

    // ScratchPad
    async getScratchPadValue(quoteID: string, key: string): Promise<Record<string, any> | undefined> {
        return QuoteAPI.call("GET", `/quoting/quotes/${quoteID}/scratchpad/${key}`, this.underwriter);
    }

    async setScratchPadValue(quoteID: string, key: string, value: any): Promise<PetQuote | undefined> {
        return QuoteAPI.call("PATCH", `/quoting/quotes/${quoteID}/scratchpad/${key}`, this.underwriter, { data: value });
    }

    async deleteScratchPadValue(quoteID: string, key: string): Promise<PetQuote | undefined> {
        return QuoteAPI.call("DELETE", `/quoting/quotes/${quoteID}/scratchpad/${key}`, this.underwriter);
    }

    // Member Center
    async verifyEmailIsRegistered(email: string): Promise<boolean> {
        return QuoteAPI.call("GET", `/utils/email/verify`, this.underwriter, { params: { email } });
    }

    async createMemberCenterRegistration(accountId: string, postalCode: string, password: string) {
        return QuoteAPI.call("POST", `/accounts/${accountId}/register`, this.underwriter, { data: { postalCode, password } });
    }

    async verifyMemberCenterRegistration(accountId: string) {
        return QuoteAPI.call("GET", `/accounts/${accountId}`, this.underwriter);
    }

    //Email Suggest
    async verifyEmailDomainExtensionIsValid(email: string) {
        return QuoteAPI.call("GET", `/utils/email/risk-status`, this.underwriter, { params: { email } });
    }

    // PTZ Quote
    static async createPtzUsQuote(quote: PTZQuote) {
        return QuoteAPI.callPtz("POST", "/quotes", { data: quote });
    }

    static async updateBillingInformation(quoteId: string, billingInfo: PTZBillingInformation) {
        await axios.put(`${PublicConfig.PTZ_US.UNDERWRITER_API_URL}/quotes/${quoteId}/BillingInformation`, billingInfo);
    }

    static async updateBillingAddress(quoteId: string, billingAddress: Address) {
        await axios.put(`${PublicConfig.PTZ_US.UNDERWRITER_API_URL}/quotes/${quoteId}/BillingAddress`, billingAddress);
    }

    // todo: spot-types payment intent response
    static async createPaymentIntent(quoteID: string, amount: number) {
        return QuoteAPI.callPtz("POST", `/quotes/${quoteID}/payment-intent/${amount}`);
    }

    // Rewards (Saasquatch)
    async getRewardsShareLink(quote: Quote) {
        return QuoteAPI.call("POST", `/rewards/refer-a-friend`, this.underwriter, {
            data: quote
        });
    }
}
